@import '../../../styles/colors';

.bank-not-rf-block {
  &__bankBlockCheckbox {
    display: flex;
    margin: 5px 0px !important;
      i {
          margin: 0 10px 0 0 !important;
      }

      p {
          margin: 0 0 1em;
          line-height: 1.65em;
      }
  }
}
