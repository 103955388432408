@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.scroll-datepicker-wrapper {
  width: 100%;
}

.fieldWrap.date {
  position: relative;

  svg {
    position: absolute;
    top: 7px;
    left: 5px;
    z-index: 1;
    pointer-events: none;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker__tab-loop {
    width: unset !important;
  }
  
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
      input {
        padding-left: 35px;

        &::-ms-clear {
          display: none;
        }
      }
      .input-field {
        padding: 0 !important;
        margin-bottom: 0 !important;
        min-height: unset !important;

        &__scroll {
          width: 100%;
        }
      }
      .input-field .field.formInput {
        width: 100% !important;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 50;
  }

}

.error.date {
  // align-self: flex-end;
  margin-right: 7px;
}

.formInput {
  .error {
    padding-top: 0;
  }
}
