@import './../../../../../../styles/colors';

.strategy-basket-creating {
  background-color: $whiteColor;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);

  &__assets {
    width: 100%;

    .item[color="1"] {
      color: $red !important;
    }

    .item[color="2"] {
      color: $orange !important;
    }

    .item[color="3"] {
      color: $yellow !important;
    }

    .item[color="4"] {
      color: $green !important;
    }

    .item[color="grey"] {
      color: $gray !important;
    }
  }

  &__assets-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__asset-warning {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }

  &__class-warning {
    color: $errorColor;
  }

  &__percent-input {
    width: 200px !important;
    display: flex;
  }

  &__percent-input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__percent {
    display: flex;
    align-items: center;
    margin: 0 0 0 7px;
    font-size: 18px;
  }

  &__autocall-enabled-decs {
    color: $checkColor;
  }

  &__autocall-disabled-decs {
    color: $errorColor;
  }

  &__popup-info {
    margin: 0px;
  }

  &__option {
    display: flex;
  }

  &__lookback {
    width: 100%;
    text-align: start;
  }
  &__lookback-title {
    margin-left: 2rem;
  }

  &__sfee {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: start;
  }

  &__input-margin {
    width: 144px !important;
    margin-left: 0px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }

  &__input-container p {
    margin-top: 5px;
    margin-left: 0;
    line-height: normal;
  }

  &__option-text {
    margin-left: 5px;
  }

  &__option-text-error {
    margin-left: 5px;
    color: $errorColor;
  }

  &__button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

}
